var canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;

function init() {
    const _LOCATION_ = window.location, _SOURCE_ = '';

    canvas = document.getElementById('canvas');
    anim_container = document.getElementById('animation_container');
    dom_overlay_container = document.getElementById('dom_overlay_container');
    /* Guide */
    createjs.MotionGuidePlugin.install();
    let
        composition = AdobeAn.getComposition(Object.keys(AdobeAn.compositions)[0]),
        loader = (new createjs.LoadQueue(false)),
        preload = Boolean(document.getElementById('app').getAttribute('data-preload') === 'true'),
        video = Boolean(document.getElementById('app').getAttribute('data-video') === 'true'),
        uri = `${_LOCATION_.protocol}//${_LOCATION_.host}${_SOURCE_}`;

    if (preload) {
        let
            element_div = document.createElement('DIV'),
            element_img = document.createElement('IMG');

        element_div.setAttribute('id', '_preload_div_');
        element_img.setAttribute('src', `${uri}/public/images/pre-loader.gif`);

        element_div.appendChild(document.createElement('SPAN'));
        element_div.appendChild(element_img);

        document.getElementById('app').appendChild(element_div);
    }

    loader.addEventListener('fileload', handleFileLoad.bind({composition: composition}));

    loader.addEventListener('complete', handleComplete.bind({
        composition: composition,
        full_screen: Boolean(document.getElementById('app').getAttribute('data-full-screen') === 'true'),
        preload: preload,
        video: video
    }));

    loader.loadManifest(composition.getLibrary().properties.manifest);
}

/**
 *
 * @param event
 */
function handleFileLoad(event) {
    let images = this.composition.getImages();

    if (event && (event.item.type === 'image')) {
        images[event.item.id] = event.result;
    }
}

/**
 *
 * @param event
 */
function handleComplete(event) {
    /* This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage. */
    let
        library = this.composition.getLibrary(),
        sprite_sheet = this.composition.getSpriteSheet(),
        queue = event.target,
        ssMetadata = library.ssMetadata,
        domContainers = [];

    for (let i = 0; i < ssMetadata.length; i++) {
        sprite_sheet[ssMetadata[i].name] = (new createjs.SpriteSheet({
            'images': [queue.getResult(ssMetadata[i].name)],
            'frames': ssMetadata[i].frames
        }));
    }

    if (this.preload) {
        let _preload_div_ = document.getElementById('_preload_div_');
        _preload_div_.style.display = 'none';
        canvas.style.display = 'block';
        domContainers.push(_preload_div_)
    }

    if (this.video) {
        domContainers.push(document.getElementById('_video_'));
    }

    exportRoot = (new library[library_name]());
    stage = (new library.Stage(canvas));
    stage.enableMouseOver();

    /* Registers the "tick" event listener. */
    fnStartAnimation = function () {
        stage.addChild(exportRoot);
        createjs.Ticker.framerate = library.properties.fps;
        createjs.Ticker.addEventListener('tick', stage);
    };

    /* Code to support hidpi screens and responsive scaling. */
    AdobeAn.makeResponsive(true, 'both', true, 1, [canvas, ...domContainers, anim_container, dom_overlay_container]);

    let full_screen = this.full_screen;

    function allTapLoad() {
        if (typeof(AllTap) !== 'undefined') {
            AllTap.onLoad({
                full_screen: full_screen,
                library: {[`_this_${library_name}_library`]: library}
            });
            createjs.Ticker.removeEventListener('tick', allTapLoad);
        } else if (typeof(parent.AllTap) !== 'undefined') {
            parent.AllTap.onLoad({
                library: {[`_this_${library_name}_library`]: library}
            });
            createjs.Ticker.removeEventListener('tick', allTapLoad);
        }
    }

    createjs.Ticker.addEventListener('tick', allTapLoad);
    AdobeAn.compositionLoaded(library.properties.id);
    fnStartAnimation();
}

window.addEventListener('load', init);